export default {
    welcome: {
        ru: `
            <p>Вы пришли в магазин продуктов.</p>
            <p><strong>Пожалуйста, совершите Вашу обычную покупку, как Вы это делаете в обычной жизни.</strong></p>
        `,
        uz: `
            <p>Сиз озиқ-овқат маҳсулотлари дўконига келдингиз.</p>
            <p><strong>Илтимос, сиз кундалик ҳаётда қилганингиз каби, одатдаги харидингизни амалга оширинг.</strong></p>
        `,
        kz: `
            <p>Сіз азық-түлік дүкеніне келдіңіз.</p>
            <p><strong>Қалыпты өміріңізде жасайтындай қалыпты саудаңызды жасауыңызды сұраймыз.</strong></p>
        `
    },
    continue: {
        ru: 'Продолжить',
        uz: 'Давом этиш',
        kz: 'Жалғастыру'
    },
    goBack: {
        ru: 'Назад',
        uz: 'Орқага',
        kz: 'Артқа'
    },
    start: {
        ru: 'Начать',
        uz: 'Бошлаш',
        kz: 'Бастау'
    },
    complete: {
        ru: 'Завершить покупки',
        uz: 'Харидларни якунлаш',
        kz: 'Сатып алуды аяқтау'
    },
    addToCart: {
        ru: 'Положить в корзину',
        uz: 'Саватчага қўшиш',
        kz: 'Себетке қосу'
    },
    cart: {
      ru: 'Корзина',
      uz: 'Сават',
      kz: 'Себет'
    },
    instruction: {
        ru: `
            <p>Нажмите на товар и вы сможете увидеть информацию о нем.</p>
            <p><strong>Перетащите товар в корзину для покупки.</strong></p>
            <p>Чтобы убрать товар из корзины нажмите Х.</p>
        `,
        uz: `
            <p>Маҳсулот устига босинг ва сиз у ҳақидаги маълумотни кўра оласиз.</p>
            <p><strong>Маҳсулотни харидлар саватига тортиб ўтказинг.</strong></p>
            <p>Маҳсулотни саватдан олиб ташлаш учун Х ни босинг.</p>
        `,
        kz: `
            <p>Тауарға басыңыз және сіз ол туралы ақпаратты көре аласыз.</p>
            <p><strong>Сатып алу үшін тауарды себетке апарыңыз.</strong></p>
            <p>Тауарды себеттен алып тастау үшін Х-ты басыңыз.</p>
        `,
    },
    findProduct: {
        step0: {
            ru: 'Пожалуйста, купите &rarr; Чипсы &rarr; Сухарики &rarr; Кукурузные палочки, так, как бы вы обычно покупаете их придя в магазин. Вы можете купить такое количество упаковок чипсов, сухариков и кукурузных палочек, как это делаете в обычном магазине.',
            uz: 'Илтимос, сиз одатга дўконга келиб харид қилганингиздек  &rarr; Чипслар &rarr; Сухариклар &rarr; Маккажўхори таёқчаларини сотиб олинг. Сиз, одатдаги дўконда харид қилганингиз каби шунча миқдордаги чипслар, сухариклар ва маккажўхори таёқчалар ўрамларини сотиб олишингиз мумкин.',
            kz: 'Өтінемін, сатып алыңыз &rarr; Чипсылар &rarr; Кептірілген нандар &rarr; Жүгері таяқшалары, себебі сіз әдетте дүкенге келгенде оларды сатып аласыз. Сіз кәдімгі дүкенде сатып алатындай, чипстер, кептірілген нан және жүгері таяқшаларының қаптамаларын сатып ала аласыз.'
        },
        step1: {
            ru: `<p>Отлично, Вы завершили покупки. А теперь найдите на полке чипсы <strong>Лейс Классические со вкусом Шашлыка, упаковка 70г</strong>. Положите их в корзину и нажмите на кнопку "завершить покупки".</p>`,
            uz: `<p>Жуда яхши, сиз харидларни якунладингиз. Энди эса растада <strong>Кабоб таъмли классик  Lays, 70 g ўрамини</strong> топинг. Уларни саватга солинг ва "харидларни якунлаш" тугмачасини босинг.</p>`,
            kz: `<p>Керемет, сіз сатып алуды аяқтадыңыз. Енді сөреден кәуап дәмі бар <strong>Lays Классикалық чипстерін табыңыз, 70 г қаптама</strong>. Оларды себетке cалып, "сатып алуды аяқтау" түймесін басыңыз.</p>`,
        },
        step2: {
            ru: `<p>Найдите на полке <strong>Хрустим Багет со вкусом Сырное Ассорти, упаковка 60г</strong>. Положите их в корзину и нажмите на кнопку "завершить покупки".</p>`,
            uz: `<p>Жавонда <strong>Пишлоқли ассорти таъмли Хрусteam Багет сухариклари, 60 г ўрамни</strong> топинг. Уларни саватга солинг ва "харидларни якунлаш" тугмачасини босинг".</p>`,
            kz: `<p>Сөреден <strong>60г. қаптамадағы Ірімшік Ассорти дәмімен Хрустим Багет кептірілген нандарын</strong> тауып алыңыз. Оларды себетке cалып, "сатып алуды аяқтау" түймесін басыңыз.</p>`
        },
        step3: {
            ru: `<p>Найдите на полке <strong>кукурузные палочки Читос Кетчуп, упаковка 85 г</strong>. Положите их в корзину и нажмите на кнопку "завершить покупки".</p>`,
            uz: `<p>Жавонда <strong>Cheetos Кетчуп маккажўхори таёқчалари, 85 г ўрамни</strong> топинг. Уларни саватга солинг ва "харидларни якунлаш" тугмачасини босинг".</p>`,
            kz: `<p>Сөреден <strong>85 г. қаптамадағы Читос Кетчуп жүгері таяқшаларын</strong> тауып алыңыз. Оларды себетке cалып, "сатып алуды аяқтау" түймесін басыңыз.</p>`
        }
    },
    con5: {
        step0: {
            ru: `<p>Пожалуйста, купите → Чипсы → Сухарики, так, как вы обычно покупаете их придя в магазин. Вы можете купить такое количество упаковок чипсов, сухариков и кукурузных палочек, как это делаете в обычном магазине.</p>`,
            uz: `<p>Илтимос, сиз одатга дўконга келиб харид қилганингиздек → Чипслар → Сухариклар. Сиз, одатдаги дўконда харид қилганингиз каби шунча миқдордаги чипслар, сухариклар ва маккажўхори таёқчалар ўрамларини сотиб олишингиз мумкин.</p>`
        },
        step3: {
            ru: `<p>Найдите на полке чипсы <strong>CHEERS Сметана и лук, упаковка 130 г.</strong> Положите их в корзину и нажмите на кнопку "завершить покупки".</p>`,
            uz: `<p>Жавонда <strong>CHEERS  Қаймоқ ва пиёз, 130 г ўрамни топинг</strong>. Уларни саватга солинг ва "харидларни якунлаш" тугмачасини босинг".</p>`
        }
    },
    zoomIntrucrion: {
        ru: 'Нажмите на область за продуктами (на полках) или на иконку зума, чтобы увеличить полку:',
        uz: 'Жавонни каттайтириш учун маҳсулотлар ортидаги соҳани (жавонларда) ёки зум иконкасини босинг:',
        kz: 'Сөрені үлкейту үшін азық-түліктің артындағы аймаққа немесе зум белгішесін басыңыз:'
    },
    movingInstruction: {
        ru: 'Для перемещения пользуйтесь элементами навигации на боковых панелях экрана:',
        uz: 'Ҳаракатланиш учун экраннинг ён панелларидаги навигация элементларидан фойдаланинг:',
        kz: 'Жылжыту үшін экранның бүйір панельдеріндегі навигация элементтерін қолданыңыз:'
    },
    returnInstruction: {
        ru: 'Чтобы вернуться к заданию, сверните экран.',
        uz: 'Топшириққа қайтиш учун экранни пастга туширинг.',
        kz: 'Тапсырмаға оралу үшін экранды қайырыңыз.'
    },
    familiarize: {
        ru: 'Ознакомьтесь, пожалуйста, с полкой. Увеличьте экран и двигайтесь вдоль полки. Уменьшите экран, чтобы приступить к заданию.',
        uz: 'Илтимос, раста билан танишиб чиқинг. Экранни катталаштиринг ва раста бўйлаб ҳаракатланинг.',
        kz: 'Сөреге қараңызшы. Экранды үлкейтіп, сөре бойымен жылжытыңыз. Тапсырманы бастау үшін экранды кішірейтіңіз.'
    }
}
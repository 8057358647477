<template>
  <div id="app"><Shelves /></div>
</template>

<script>
import Shelves from "./components/Shelves";

export default {
  name: "App",
  components: {
    Shelves
  }
};
</script>

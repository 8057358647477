import { render, staticRenderFns } from "./IconMinimize.vue?vue&type=template&id=5f5491d4&scoped=true&"
import script from "./IconMinimize.vue?vue&type=script&lang=js&"
export * from "./IconMinimize.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f5491d4",
  null
  
)

export default component.exports
<template>
  <div class="info">
    <div class="info__text _wide" v-if="screenNumber === 1">
      <transition name="fade" mode="out-in">
        
        <div v-if="stepNumber === 0" key="start">
          <div class="info__block" v-html="text.welcome.ru"/>
          <div class="info__block" v-html="text.welcome[lang]"/>
        </div>
        
        <div v-else-if="stepNumber === 1" key="instruction">
          <div class="info__block" v-html="text.instruction.ru"/>
          <div class="info__block" v-html="text.instruction[lang]"/>
          <img :src="require(`@/assets/img/cart-instruction.png`)" alt="Instruction">
        </div>
        
        <div v-else-if="stepNumber === 2" key="zooming" class="zoomimg">
          <p>{{text.zoomIntrucrion.ru}}</p>
          <p>{{text.zoomIntrucrion[lang] }}</p>
          <div class="zooming__buttons">
            <div class="zooming__button">
              <IconMaximize :size="20"/>
            </div>
            <div class="zooming__button">
              <IconMinimaize :size="20"/>
            </div>
          </div>
          <p>{{text.movingInstruction.ru}}</p>
          <p>{{text.movingInstruction[lang]}}</p>
          <img :src="require(`@/assets/img/zoom-buttons.png`)" alt="Инструкция по зуму" class="zooming__image">
          <p>{{text.returnInstruction.ru}}</p>
          <p>{{text.returnInstruction[lang]}}</p>
        </div>

        <div v-else-if="stepNumber === 3">
          <p>{{text.familiarize.ru}}</p>
          <p>{{text.familiarize[lang]}}</p>
          <img v-if="screenImage" :src="screenImage" alt="Мини картинка планограммы">
        </div>

        <div v-else key="instruction">
          <div v-html="text.con5.step0.ru" v-if="con === '5'"/>
          <div v-html="text.findProduct[`step${screenNumber - 1}`].ru" v-else/>
          <div v-html="text.con5.step0[lang]" v-if="con === '5'"/>
          <div v-html="text.findProduct[`step${screenNumber - 1}`][lang]" v-else/>
        </div>
        
      </transition>
    </div>

    <div class="info__text" v-else-if="screenNumber > 1">
      <div v-html="text.con5.step3.ru" v-if="con === '5' && screenNumber === 4"/>
      <div v-html="text.findProduct[`step${screenNumber - 1}`].ru" v-else/>
      <div v-html="text.con5.step3[lang]" v-if="con === '5' && screenNumber === 4"/>
      <div v-html="text.findProduct[`step${screenNumber - 1}`][lang]" v-else/>
    </div>

    <transition name="fade" mode="out-in">
      <button class="info__button button"
              @click="clickButton"
              key="start-button"
              v-if="(screenNumber === 1 && stepNumber === 0) || screenNumber > 1">
        {{text.continue.ru}} / {{text.continue[lang]}}
      </button>
      <div v-else-if="screenNumber === 1 && stepNumber === 1" class="info__buttons">
        <button class="info__button button" @click="stepNumber = 0" key="instruction-button">
          {{text.goBack.ru}}<br>{{text.goBack[lang]}}
        </button>
        <button class="info__button button" @click="clickButton" key="instruction-button2">
          {{text.continue.ru}} {{text.continue[lang]}}
        </button>
      </div>
      <button v-else class="info__button button _secondary" @click="clickButton" key="instruction-button4">
        {{text.start.ru}} / {{text.start[lang]}}
      </button>
    </transition>

    <ul class="dots" v-if="(screenNumber === 1 && stepNumber < 3)">
      <li v-for="i in 3"
          :key="i"
          :class="{'_active': stepNumber === i - 1}"
          @click="stepNumber = i -1"
      />
    </ul>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import lang_texts from "@/assets/js/lang_texts";
import IconMaximize from "@/components/icons/IconMaximize.vue";
import IconMinimaize from "@/components/icons/IconMinimize.vue";

export default {
  name: "Info",
  components: {IconMinimaize, IconMaximize},
  props: {
    screenNumber: { type: Number, required: true },
    screenImage: { type: String, default: null },
    lang: { type: String, default: '' },
    con: { type: String, default: '' },
  },
  data() {
    return {
      stepNumber: 0,
      text: lang_texts
    }
  },
  methods: {
    clickButton() {
      this.stepNumber++;
      if (this.screenNumber === 1 && this.stepNumber < 5) {
        if ((this.screenNumber === 1 && this.stepNumber === 4) || (this.screenNumber > 1)) {
          // modal is not closing outside until you see all starting messages
          eventBus.$emit('can close outside', true);
        }
      } else {
        eventBus.$emit('close modal', {setTimout: true});
      }
    },
  },
  created () {
    // if this is a start screen popup you can't close modal by clicking outside
    if (!this.stepNumber) eventBus.$emit('can close outside', false);
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/common";

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: calc(100% - 24rem);
  margin: 12rem;

  &__text {
    width: 290rem;
    max-width: 100%;
    margin-right: 20rem;

    &._wide {
      width: 490rem;
    }

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }

    p, &:deep(p) {
      margin-bottom: 8rem;
    }

    img {
      width: 100%;
      max-height: 350rem;
      margin: auto;
      display: block;
    }
  }

  &__block:not(:last-of-type) {
    margin-bottom: 16rem;
  }

  &__buttons {
    display: flex;
    gap: 12rem;
    width: 100%;

    button {
      flex: 1;
    }
  }

  &__button {
    width: 100%;
    margin-top: 16rem;
    cursor: pointer;
  }
}

.zooming {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20rem;
    margin: 16rem 0;
  }

  &__button {
    width: 36rem;
    height: 36rem;
    padding: 7rem;
    border: 1rem solid $primary;
    border-radius: 4rem;

    svg {
      width: 20rem;
      height: 20rem;
      stroke: $primary;
    }
  }

  &__image {
    width: 200rem!important;
    height: 80rem;
  }
}

.dots {
  display: flex;
  gap: 4rem;
  margin-top: 16rem;

  li {
    width: 8rem;
    height: 8rem;
    border: 1rem solid $black;
    border-radius: 50%;
    transition: border-color .35s ease-out, background-color .35s ease-out;
    overflow: hidden;
    cursor: pointer;

    &._active {
      border-color: $primary;
      background-color: $primary;
      pointer-events: none;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .35s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

</style>

<template>
  <svg :width="size" :height="size" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.33337 18.6667H13.3334V26.6667" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.6666 13.3333H18.6666V5.33325" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.6666 13.3333L28 4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 28.0001L13.3333 18.6667" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconMinimaize",
  props: {
    size: {
      type: Number,
      default: 32
    },
  }
}
</script>

<style scoped>

</style>

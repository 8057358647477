<template>
  <li class="drag-item"
      :class="[{[item.id]: true }, zonetype]"
      :style="placeSize"
      :data-id="item.uuid"
      ref="item"
      draggable="false"
  >
    <div class="drag-item__tags" v-if="zonetype === 'basket'">
      <button @click.stop.prevent="removeItem" class="drag-item__button button">
        <CloseIcon :size="16" class="modal__close" />
      </button>
    </div>

    <div class="drag-item__img" v-if="zonetype === 'basket'"
         @touchmove="moveTouchItem($event)"
         @touchend="endTouchItem($event, item.uuid)"
         @click="openModal(true)"
    >
      <img :src="image" :alt="item.name.ru"
           :style="size"
           ref="itemImage">
    </div>
    <div v-else
         class="drag-item__images"
         :style="quantityStyle"
    >
      <div class="drag-item__img"
           @mouseover="openModal(false)"
           @mouseleave="closeModal"
           @touchmove="moveTouchItem($event)"
           @touchend="endTouchItem($event, item.uuid)"
           @click="openModal(true)"
           v-for="index in item.quantity"
           :key="index"
      >
        <img :src="image" :alt="item.name.ru"
             :style="size"
             ref="itemImage">
      </div>
    </div>

    <div class="drag-item__price-tag"
         @click="openModal(true)"
         v-if="zonetype !== 'basket' && zonetype !== 'lenta' && item.price"
    >
      {{ item.price }}
    </div>
  </li>
</template>

<script>
import {eventBus} from "@/main";
import CloseIcon from "./icons/CloseIcon";

export default {
  name: "Item",
  props: {
    item: { type: Object, required: true },
    zonetype: { type: String },
    shelfId: { type: String, required: true },
    basketPos: { type: Object },
    isTouchDevice: { type: Boolean, required: true }
  },
  components: { CloseIcon },
  data() {
    return {
      openPopup: false,
      width: 0,
      height: 0,
      mobilePos: {
        top: 0,
        left: 0
      },
      CELL_SIZE: 56
    }
  },
  methods: {
    openModal(isPopup) {
      this.$refs.item.setAttribute('aria-grabbed', false);
      if (isPopup && this.zonetype !== 'basket') {
        this.openPopup = false;
        eventBus.$emit('open modal', {
          item: this.item,
          pos: null,
          shelfId: this.shelfId
        })
      } else if (this.zonetype !== 'basket' && this.item.price) {
        this.openPopup = true;
        const pos = this.$refs.item.getBoundingClientRect();
        eventBus.$emit('open modal', {
          item: this.item,
          pos: {
            top: pos.bottom,
            left: pos.right
          }
        })
      }
    },
    closeModal() {
      if (this.openPopup) {
        eventBus.$emit('close modal', {});
      }
    },
    removeItem() {
      eventBus.$emit('remove item', this.item.uuid);
    },
    moveTouchItem() {

    },
    endTouchItem() {

    }
  },
  computed: {
    image() {
      const media = this.item.media[0];

      if (media) {
        return media.original_url
      }

      if (media && media.generated_conversions && media.generated_conversions.length < 1) {
        return `${process.env.VUE_APP_API_URL}/storage/${media.id}/${media.file_name}`;
      } else if (media && media.generated_conversions && Object.values(media.generated_conversions).every(el => el)) {
        return `${process.env.VUE_APP_API_URL}/storage/${media.id}/conversions/${media.name}-${this.Size}.png`;
      } else {
        return '';
      }
    },
    imageSize() {
       return 'thumb_200';
    },
    placeSize() {
      if (this.zonetype === 'lenta') {
        return;
      } else {
        return {width: `${this.CELL_SIZE * this.item.places * this.item.quantity}rem`};
      }
    },
    size() {
      return {width: this.item.size + 'rem'};
    },
    quantityStyle() {
      if (this.zonetype === 'lenta') {
        return;
      } else {
        return {gridTemplateColumns: `repeat(${this.item.quantity}, 1fr)`};
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.itemImage) {
        this.height = this.$refs.itemImage.clientHeight;
        this.width = this.$refs.itemImage.clientWidth;
      }
    });
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/common";

.drag-item {
  flex: none;
  position: relative;
  transition: $ease-out;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 55%;

  &:not(:last-of-type) {
    margin-right: .5rem;
  }

  &:not(.basket) {
    pointer-events: none;
  }

  /* items grabbed state */
  &[aria-grabbed="true"] {
    background-color: transparent;

    .drag-item__price-tag {
      visibility: hidden;
      height: 0;
    }
  }

  &:hover {
    .drag-item__tags {
      display: flex;
    }
    &:not(.basket) {
      .drag-item__img {

      }

      .drag-item__tags {
        bottom: 0;
      }
    }

    .modal {
      opacity: 1;
    }
  }

  &.lenta {
    height: 100%;
  }

  &.basket {
    height: 112rem;
    min-height: 112rem;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem;
    background-color: white;
    margin-right: 0;
    margin-top: -1rem;

    &:nth-child(1) {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      margin-top: auto !important;
    }

    &:nth-last-child(1) {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &__tags {
    position: absolute;
    top: 10rem;
    right: 50%;
    transform: translateX(58rem);
    display: flex;
    z-index: 3;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    border: none;
    cursor: pointer;

    svg {
      width: 12rem;
      height: 12rem;
      stroke: black;
      transition: all 0.25s ease-in-out;
    }

    &:hover svg {
      stroke: white;
    }
  }

  &__images {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    width: 100%;

    .lenta & {
      gap: 5rem;
    }

    &:hover {

    }
  }

  &__img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    transition: transform .3s ease;
    will-change: transform;
    z-index: 2;
    pointer-events: auto;

    img {
      width: 100%;
      max-height: 82rem;
      transition: transform .3s ease;
      will-change: transform;
    }

    &:not(.basket):hover img {
      transform: scale(1.1) translateY(-10rem);
    }

    .lenta & {
      & img {
        max-height: 100%;
      }
    }

    .basket & {
      width: auto;
      height: 100%;
      max-height: 100%;

      img {
        max-height: 100%;
      }

      &:hover img {
        transform: none;
      }
    }
  }

  &__open {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
  }

  &__price-tag {
    position: absolute;
    bottom: -12rem;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 16rem;
    height: 10rem;
    padding: 0 .8rem;
    font-size: 6rem;
    line-height: 1;
    white-space: nowrap;
    background: $price-bg;
    border: 1rem solid $border-color;

    .fridge & {
      bottom: -6rem;
      height: 7rem;
    }
  }

}

</style>

<template>
  <section class="controls">
    <div class="js-rem-example" ref="remEx"></div>
    <div
        class="scroll-button scroll-button--right"
        @mouseenter="onMouseEnter('right')"
        @mouseleave="onMouseLeave"
        v-if="isZooming"
    >
      <ArrowIcon />
    </div>
    <div
        class="scroll-button scroll-button--left"
        @mouseenter="onMouseEnter('left')"
        @mouseleave="onMouseLeave"
        v-if="isZooming"
    >
      <ArrowIcon />
    </div>
    <div
        class="scroll-button scroll-button--bottom"
        @mouseenter="onMouseEnter('bottom')"
        @mouseleave="onMouseLeave"
        v-if="isZooming"
    >
      <ArrowIcon />
    </div>
    <div
        class="scroll-button scroll-button--top"
        @mouseenter="onMouseEnter('top')"
        @mouseleave="onMouseLeave"
        v-if="isZooming"
    >
      <ArrowIcon />
    </div>
    <div
        class="scroll-button scroll-button--right-top"
        @mouseenter="onMouseEnter('right-top')"
        @mouseleave="onMouseLeave"
        v-if="isZooming"
    ></div>
    <div
        class="scroll-button scroll-button--right-bottom"
        @mouseenter="onMouseEnter('right-bottom')"
        @mouseleave="onMouseLeave"
        v-if="isZooming"
    ></div>
    <div
        class="scroll-button scroll-button--left-top"
        @mouseenter="onMouseEnter('left-top')"
        @mouseleave="onMouseLeave"
        v-if="isZooming"
    ></div>
    <div
        class="scroll-button scroll-button--left-bottom"
        @mouseenter="onMouseEnter('left-bottom')"
        @mouseleave="onMouseLeave"
        v-if="isZooming"
    ></div>

    <button
        class="zoom-button button"
        @click="onClickCloseZoom"
        v-if="isZooming"
    >
      <IconMinimize />
    </button>
    <button
        class="zoom-button button"
        @click="onClickZoomFirst"
        v-else
    >
      <IconMaximize />
    </button>
  </section>
</template>

<script >
import ArrowIcon from "@/components/icons/ArrowIcon.vue";
import IconMinimize from "@/components/icons/IconMinimize.vue";
import IconMaximize from "@/components/icons/IconMaximize.vue";
import {eventBus} from "@/main";

export default {
  name: 'Controls',
  inheritAttrs: true,

  components: {IconMaximize, IconMinimize, ArrowIcon},

  props: {
    clickedRack: { type: Event },
  },

  data() {
    return {
      stellageZoomStyle: {
        scale: 1,
        x: 0,
        y: 0,
      },
      isZooming: false,
      isFirstZoom: true,
      scrollInterval: null,
      elementTargetNow: null
    }
  },

  methods: {

    refreshData() {
      this.$emit('refreshData', {
        stellageZoomStyle: this.stellageZoomStyle,
        isZooming: this.isZooming,
        isFirstZoom: this.isFirstZoom
      })
    },

    onClickCloseZoom() {
      eventBus.$emit('on zoom', false);
      this.isZooming = false;
      this.isFirstZoom = true;
      this.stellageZoomStyle = {
        scale: 1,
        x: 0,
        y: 0,
      };

      this.elementTargetNow = null;

      this.refreshData();
    },

    calculateDistanceFromParent(parentRect, elementRect) {
      const distanceX = elementRect.left - parentRect.left;
      const distanceY = elementRect.top - parentRect.top;

      return {x: distanceX, y: distanceY};
    },

    onClickZoomFirst() {
      eventBus.$emit('on zoom', true);
      this.isZooming = true;
      this.isFirstZoom = false;

      const zoom = 2;
      this.stellageZoomStyle.scale = zoom;
      const distance = this.calculateDistanceFromParent(
              this.$parent.$refs.dragListWrapper.getBoundingClientRect(),
              this.$parent.$refs.rackBg[0].getBoundingClientRect()
          ),
          currentX = Math.floor(Math.abs(distance.x) * 0.6),
          currentY = Math.floor(Math.abs(distance.y) * 0.8);

      this.stellageZoomStyle.x = currentX;
      this.stellageZoomStyle.y = currentY;

      this.refreshData();
    },

    onClickRack(e) {
      const zoom = 2;
      this.stellageZoomStyle.scale = zoom;
      const target = e.target,
          type = target.dataset.type,
          distance = this.calculateDistanceFromParent(
              this.$parent.$refs.dragListWrapper.getBoundingClientRect(),
              {
                top: e.pageY,
                left: e.pageX,
              }
          ),
          currentX = Math.floor(Math.abs(distance.x) * .8),
          currentY = Math.floor(Math.abs(distance.y) * .6);

      if (type === "basket" || target.classList.contains('drag-item__open')) {
        return;
      }

      target.classList.add("target");
      this.elementTargetNow?.classList.remove("target");
      this.elementTargetNow = target;
      this.isZooming = true;

      if (this.isFirstZoom) {
        this.isFirstZoom = false;
        this.stellageZoomStyle.x = currentX;
        this.stellageZoomStyle.y = currentY;
        this.refreshData();
        return;
      }

      this.stellageZoomStyle.x = currentX / zoom;
      this.stellageZoomStyle.y = currentY / zoom;
      this.refreshData();
    },

    setInterval(interval) {
      if(this.scrollInterval) {
        clearInterval(this.scrollInterval)
      }
      this.scrollInterval = interval
    },

    onMouseLeave() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
      }
    },

    onMouseEnter(float) {
      const SCROLL_SPEED = 5;
      const BORDER = {
        top: this.inRem(68),
        right: this.inRem(78),
        bottom: this.inRem(97),
        left: this.inRem(47)
      }
      let clientWidth, clientHeight;
      if(this.isZooming) {
        clientWidth =  this.$parent.$refs.dragListWrapper.scrollWidth * 0.4 * this.stellageZoomStyle.scale;
        clientHeight = this.$parent.$refs.dragListWrapper.clientHeight * 0.2 * this.stellageZoomStyle.scale;
      } else {
        clientWidth =  this.$parent.$refs.dragListWrapper.scrollWidth * 0.14 * this.stellageZoomStyle.scale;
        clientHeight = 0;
      }
      this.isFirstZoom = false;

      this.onMouseLeave();

      switch (float) {
        case 'top':
          this.scrollInterval = setInterval(() => {
            this.stellageZoomStyle.y = this.stellageZoomStyle.y <= BORDER.top ? this.stellageZoomStyle.y : this.stellageZoomStyle.y - SCROLL_SPEED;
            this.refreshData();
          }, 1);
          break;
        case 'bottom':
          this.scrollInterval = setInterval(() => {
            this.stellageZoomStyle.y = this.stellageZoomStyle.y >= clientHeight + BORDER.bottom ? this.stellageZoomStyle.y : this.stellageZoomStyle.y + SCROLL_SPEED;
            this.refreshData();
          }, 1);
          break;
        case 'right':
          this.scrollInterval = setInterval(() => {
            if(this.stellageZoomStyle.x >= clientWidth + BORDER.right) {
              clearInterval(this.scrollInterval);
              return;
            }
            this.stellageZoomStyle.x = this.stellageZoomStyle.x + SCROLL_SPEED;
            this.refreshData();
          }, 1);
          break;
        case 'right-top':
          this.scrollInterval = setInterval(() => {
            this.stellageZoomStyle.x = this.stellageZoomStyle.x >= clientWidth + BORDER.right ? this.stellageZoomStyle.x : this.stellageZoomStyle.x + SCROLL_SPEED;
            this.stellageZoomStyle.y = this.stellageZoomStyle.y <= BORDER.top ? this.stellageZoomStyle.y : this.stellageZoomStyle.y - SCROLL_SPEED;
            this.refreshData();
          }, 1);
          break;
        case 'right-bottom':
          this.scrollInterval = setInterval(() => {
            this.stellageZoomStyle.x = this.stellageZoomStyle.x >= clientWidth + BORDER.right ? this.stellageZoomStyle.x : this.stellageZoomStyle.x + SCROLL_SPEED;
            this.stellageZoomStyle.y = this.stellageZoomStyle.y >= clientHeight + BORDER.bottom ? this.stellageZoomStyle.y : this.stellageZoomStyle.y + SCROLL_SPEED;
            this.refreshData();
          }, 1);
          break;
        case 'left':
          this.scrollInterval = setInterval(() => {
            if(this.stellageZoomStyle.x <= 0) {
              clearInterval(this.scrollInterval);
              return;
            }
            this.stellageZoomStyle.x = this.stellageZoomStyle.x - SCROLL_SPEED;
            this.refreshData();
          }, 1);
          break;
        case 'left-top':
          this.scrollInterval = setInterval(() => {
            this.stellageZoomStyle.x = this.stellageZoomStyle.x <= 0 ? this.stellageZoomStyle.x : this.stellageZoomStyle.x - SCROLL_SPEED;
            this.stellageZoomStyle.y = this.stellageZoomStyle.y <= BORDER.top ? this.stellageZoomStyle.y : this.stellageZoomStyle.y - SCROLL_SPEED;
            this.refreshData();
          }, 1);
          break;
        case 'left-bottom':
          this.scrollInterval = setInterval(() => {
            this.stellageZoomStyle.x = this.stellageZoomStyle.x <= 0 ? this.stellageZoomStyle.x : this.stellageZoomStyle.x - SCROLL_SPEED;
            this.stellageZoomStyle.y = this.stellageZoomStyle.y >= clientHeight + BORDER.bottom ? this.stellageZoomStyle.y : this.stellageZoomStyle.y + SCROLL_SPEED;
            this.refreshData();
          }, 1);
          break;
      }
    }
  },

  watch: {
    clickedRack: {
      handler(value) {
        eventBus.$emit('on zoom', true);
        this.onClickRack(value);
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/common";

.zoom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  top: 50rem;
  right: 45rem;
  width: 48rem;
  height: 48rem;
  padding: 8rem;

  svg {
    width: 32rem;
    height: 32rem;
    stroke: $primary;
  }

  &:hover svg {
    stroke: $white;
  }
}

.scroll-button {
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($white, 0.9);
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }

  &--right {
    top: 50%;
    transform: translateY(-50%);
    width: 48rem;
    right: 0;
    height: 50vh;
    border-radius: 8rem 0 0 8rem;

  }
  &--left {
    top: 50%;
    transform: translateY(-50%);
    width: 48rem;
    left: 0;
    height: 50vh;
    border-radius: 0 8rem 8rem 0;

    svg {
      transform: rotate(180deg);
    }
  }
  &--bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50vw;
    height: 48rem;
    border-radius: 8rem 8rem 0 0;

    svg {
      transform: rotate(90deg);
    }
  }
  &--top {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50vw;
    height: 48rem;
    border-radius: 0 0 8rem 8rem;

    svg {
      transform: rotate(-90deg);
    }
  }
  &--right-top {
    top: 0;
    right: 0;
    width: 48rem;
    height: 48rem;
    border-radius: 0 0 0 8rem;
  }
  &--right-bottom {
    bottom: 0;
    right: 0;
    width: 48rem;
    height: 48rem;
    border-radius: 8rem 0 0 0;
  }
  &--left-top {
    top: 0;
    left: 0;
    width: 48rem;
    height: 48rem;
    border-radius: 0 0 8rem 0;
  }
  &--left-bottom {
    bottom: 0;
    left: 0;
    width: 48rem;
    height: 48rem;
    border-radius: 0 8rem 0 0;
  }
}

</style>
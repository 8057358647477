<template>
  <div class="item-details">
    <CloseIcon v-if="!posModal"
               :size="16"
               @click.native="closeModal"
               class="item-details__close"
    />

    <div class="item-details__content">
      <div class="item-details__image" :class="{'_full': !posModal}">
        <img :src='image' :alt="item.name" v-if="image">
      </div>
      <div class="item-details__info">

        <div v-for="(name, i, index) in item.name" :key="i + index">{{name}}</div>
        <div v-for="(name, i) in item.category.name" :key="i"><span>{{name}}</span></div>
        <div><span>{{ item.volume }}</span></div>
        <div v-if="item.price"><span>{{ item.price }}</span> </div>

        <div v-if="!posModal" class="counter" :class="{'_disabled': basketItemsQnty > 9}">
          <button class="counter__button _dec" :class="{'_disabled': isDisabled}" @click="changeQty('dec')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M2.34326 8H13.657" stroke="black" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </button>
          <input class="counter__num" type="number" v-model.number="qty" @input="changeQty">
          <button class="counter__button _inc"
                  @click="changeQty('inc')"
                  :disabled="!canInc"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8.00012 2.34315V13.6569" stroke="black" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M2.34326 8H13.657" stroke="black" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

      </div>
    </div>
    <div class="item-details__message" v-if="basketItemsQnty > 9 && posModal">В корзину можно добавить максимум 10 продуктов.</div>
    <div class="item-details__button-container" v-else-if="!posModal">
      <div v-if="basketItemsQnty > 9">В корзину можно добавить максимум 10 продуктов.</div>
      <button class="item-details__button button" :disabled="basketItemsQnty > 9" @click="addToCart">
        {{text.addToCart.ru}} / {{text.addToCart[lang]}}
      </button>
    </div>

  </div>
</template>

<script>
import {eventBus} from "@/main";
import CloseIcon from "@/components/icons/CloseIcon";
import lang_texts from "@/assets/js/lang_texts";

export default {
  name: "ItemDetails",
  components: {CloseIcon},
  props: {
    item: {type: Object, required: true},
    posModal: {type: Object, required: false},
    shelfId: {type: String},
    basketItemsQnty: {type: Number},
    lang: { type: String, default: '' }
  },
  data() {
    return {
      qty: 1,
      isDisabled: true,
      text: lang_texts
    }
  },
  methods: {
    closeModal() {
      eventBus.$emit('close modal', {});
    },
    changeQty(action) {
      if (action === 'dec') {
        if (this.qty > 1) {
          this.qty--;
        }
      } else if (action === 'inc') {
        this.qty++;
      } else {
        if (this.qty < 1) this.qty = 1;
      }

      this.isDisabled = this.qty === 1;
    },
    addToCart() {
      for (let i = 0; i < this.qty; i++) {
        eventBus.$emit('move to basket', {shelf: this.shelfId, uuid: this.item.uuid});
      }
      eventBus.$emit('close modal', {});
    }
  },
  computed: {
    image() {
      if (this.item.media[0]) {
        return this.item.media[0].original_url;
      }

      if (this.item.media[0].generated_conversions.length < 1) {
        return `${process.env.VUE_APP_API_URL}/storage/${this.item.media[0].id}/${this.item.media[0].file_name}`
      } else if (Object.values(this.item.media[0].generated_conversions).every(el => el)) {
        return `${process.env.VUE_APP_API_URL}/storage/${this.item.media[0].id}/conversions/${this.item.media[0].name}-${this.imageSize}.png`
      } else {
        return ''
      }
    },
    imageSize() {
      return this.posModal ? 'thumb_200' : 'thumb_320'
    },
    canInc() {
      return 10 - this.basketItemsQnty - this.qty;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/common";

.item-details {
  width: 100%;
  margin: 4rem 0 12rem;

  &__close {
    position: absolute;
    top: 16rem;
    right: 12rem;
    cursor: pointer;
    stroke: $black;
  }

  &__content {
    display: flex;
    gap: 16rem;
  }

  &__image {
    position: relative;
    width: 122rem;
    height: 122rem;
    flex: none;

    img {
      position: absolute;
      inset: 12rem;
      left: 50%;
      width: calc(100% - 24rem);
      height: calc(100% - 12rem);
      transform: translateX(-50%);
    }

    &._full {
      width: 200rem;
      height: 200rem;

      @media (max-width: 768px) {
        max-width: 50%;
      }
    }
  }

  &__info {
    min-width: 151rem;
    max-width: 240rem;
    margin: 12rem 0;

    div:not(:first-of-type) {
      margin-top: 8rem;
    }

    span {
      font-weight: bold;
    }

    @media (max-width: 768px) {
      max-width: 50%;
    }
  }

  &__message {
    margin-top: 12rem;
    text-align: center;
  }

  &__button-container {
    margin-top: 24rem;
  }

  &__button {
    width: 100%;
    margin-top: 12rem;
  }
}

.counter {
  display: flex;
  width: fit-content;
  margin-top: 16rem;
  border-radius: 4rem;

  &._disabled {
    pointer-events: none;
  }

  input, button {
    padding: 6rem 8rem;
  }

  &__button {
    height: 100%;
    cursor: pointer;
    border: 1rem solid $border-color;
    transition: border-color .25s ease-out;

    &:disabled, &._disabled {
      pointer-events: none;
    }

    &:hover, &:active {
      border-color: $primary;
    }

    svg {
      width: 16rem;
      height: 16rem;
    }

    &._dec {
      border-radius: 4rem 0 0 4rem;
    }

    &._inc {
      border-radius: 0 4rem 4rem 0;
    }
  }

  &__num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40rem;
    text-align: center;
    border: none;
    border-top: 1rem solid $border-color;
    border-bottom: 1rem solid $border-color;
    font-size: inherit;
    font-weight: 500;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

</style>
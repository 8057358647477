
import Vue from 'vue/dist/vue.js';
import VueDraggable from "vue-draggable";
import HelpersMixin from './assets/js/helpers';
import axios from 'axios';

import App from "./App";

Vue.config.productionTip = false;
Vue.use(VueDraggable);
Vue.mixin(HelpersMixin);
Vue.prototype.$http = axios;


export const eventBus = new Vue()

/* eslint-disable no-new */
new Vue({
  el: "#app",
  components: { App },
  template: "<App/>"
});

<template>
  <div class="cart button" :class="{'active': active}">
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="46" fill="none">
      <path fill-rule="evenodd" d="m39.408 22.694-1.47 13.286a5.05 5.05 0 0 1-5.011 4.501h-22.77a5.05 5.05 0 0 1-5.01-4.5l-1.47-13.287a1.374 1.374 0 0 1 1.366-1.533h32.999a1.374 1.374 0 0 1 1.366 1.533Zm-2.902 1.227H6.578L7.88 35.675a2.294 2.294 0 0 0 2.277 2.046h22.77a2.294 2.294 0 0 0 2.277-2.046l1.302-11.754Z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="M41.25 17.94v1.84c0 1.1-.434 2.152-1.208 2.928a4.116 4.116 0 0 1-2.917 1.213H5.96a4.116 4.116 0 0 1-2.917-1.213 4.147 4.147 0 0 1-1.208-2.927v-1.84c0-1.099.434-2.151 1.208-2.928a4.116 4.116 0 0 1 2.917-1.212h31.166c1.095 0 2.143.436 2.917 1.212a4.147 4.147 0 0 1 1.208 2.928Zm-2.75 0a1.377 1.377 0 0 0-1.375-1.38H5.96a1.377 1.377 0 0 0-1.375 1.38v1.84a1.377 1.377 0 0 0 1.375 1.38h31.166a1.377 1.377 0 0 0 1.375-1.38v-1.84ZM12.834 28.06c0-.761.616-1.38 1.375-1.38.758 0 1.375.619 1.375 1.38v5.52c0 .763-.617 1.38-1.375 1.38a1.378 1.378 0 0 1-1.375-1.38v-5.52ZM20.167 28.06c0-.761.616-1.38 1.375-1.38s1.375.619 1.375 1.38v5.52c0 .763-.616 1.38-1.375 1.38a1.378 1.378 0 0 1-1.375-1.38v-5.52ZM27.5 28.06c0-.761.616-1.38 1.375-1.38s1.375.619 1.375 1.38v5.52c0 .763-.616 1.38-1.375 1.38a1.378 1.378 0 0 1-1.375-1.38v-5.52Z" clip-rule="evenodd"/>
      <path fill-rule="evenodd" d="M12.689 18.557a1.372 1.372 0 0 1-1.845.618 1.382 1.382 0 0 1-.616-1.85l5.5-11.04a1.372 1.372 0 0 1 1.845-.619c.68.34.955 1.17.616 1.851l-5.5 11.04ZM32.855 17.324c.34.681.064 1.511-.616 1.851a1.372 1.372 0 0 1-1.844-.618l-5.5-11.04c-.34-.68-.064-1.51.616-1.85a1.372 1.372 0 0 1 1.844.617l5.5 11.04Z" clip-rule="evenodd"/>
    </svg>
    <div>{{text.cart.ru}} / {{text.cart[lang]}}</div>
  </div>
</template>

<script>
import lang_texts from "@/assets/js/lang_texts";

export default {
  name: "IconCart",
  props: {
    active: { type:Boolean, default: false },
    lang: { type: String, default: '' }
  },
  data() {
    return {
      text: lang_texts,
    }
  }
}

</script>

<style scoped lang="scss">
@import "../../assets/scss/common";

.cart {
  position: absolute;
  inset: 0;
  flex-direction: column;
  padding: 0 !important;
  overflow: hidden;
  z-index: 3;
  scrollbar-width: none;
  transition: all 0.25s ease-in-out;

  svg {
    fill: $primary;
  }

  &.active {
    color: $white;
    background-color: $primary;

    svg {
      fill: $white;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

</style>
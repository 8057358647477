export default {
    data() {
      return {
          rem100: null,
      }
    },
    methods: {
        cloneOverJson(obj) {
            return JSON.parse(JSON.stringify(obj));
        },
        inRem(px) {
            if (!this.rem100) {
                this.rem100 = this.$refs.remEx.offsetHeight
            }
            return this.rem100 * px / 100
        },
        async track({con, uid, ticket, e, screen, event, duration, shown = []}) {
            let id = '', p = '', stellage = ''
            if (e) {
                id = e.className.split(' ')[1]
                p = e.closest('.drag-shelf').className.split(' ')[1]
                stellage = e.closest('.drag-column').className.split(' ')[1]
            }

            const timestamp = new Date().toISOString()

            // fetch request timeout
            const controller = new AbortController()
            const timeoutId = setTimeout(() => controller.abort(), 1500)

            await fetch('https://ccscc.store/add_to_basket?' + new URLSearchParams({
                event,
                uid,
                id,
                timestamp,
                con,
                p,
                stellage,
                screen,
                shown: shown.join(","),
                ticket,
                duration
            }), { signal: controller.signal })
            clearTimeout(timeoutId)
        }
    }
}
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
    <path d="M4.467 16.8H2.1a.7.7 0 1 0 0 1.4h2.8a.7.7 0 0 0 .626-.387l.774-1.548 2.174 4.348a.7.7 0 1 0 1.252-.626L7.082 14.7l.704-1.408.731 1.096a.7.7 0 0 0 .583.312h2.1a.7.7 0 1 0 0-1.4H9.475L8.282 11.51A.714.714 0 0 0 7.7 11.2H4.9a.717.717 0 0 0-.626.387l-1.05 2.1a.7.7 0 0 0 .313.939c.341.17.768.028.939-.313l.856-1.713h1.235l-2.1 4.2ZM7.7 9.8c0 .772.628 1.4 1.4 1.4.772 0 1.4-.628 1.4-1.4 0-.772-.628-1.4-1.4-1.4-.772 0-1.4.628-1.4 1.4ZM13.3 14a.7.7 0 0 0 .7.7h7.41l-2.305 2.305a.7.7 0 1 0 .99.99l3.5-3.5a.7.7 0 0 0 0-.99l-3.5-3.5a.7.7 0 1 0-.99.99L21.41 13.3H14a.7.7 0 0 0-.7.7Z"/>
    <path d="M25.9 5.6h-9.8a.7.7 0 0 0-.7.7v4.9a.7.7 0 1 0 1.4 0V7h8.4v14h-8.4v-4.2a.7.7 0 1 0-1.4 0v4.9a.7.7 0 0 0 .7.7h9.8a.7.7 0 0 0 .7-.7V6.3a.7.7 0 0 0-.7-.7Z"/>
  </svg>
</template>

<script>
export default {
  name: "IconAway"
}
</script>

<style scoped lang="scss">

</style>
<template>
  <div>
    <img class="mobile-drag" :src="src" alt="mobile drag" :style="pos">
  </div>
</template>

<script>
export default {
  name: "MobileDrag",
  props: {
    src: { type: String, required: true },
    pos: { type: Object }
  }
}
</script>

<style lang="scss" scoped>

.mobile-drag {
  position: absolute;
  z-index: 41;
  transform: translate(-50%, -50%);
}

</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 16 16" fill="none">
    <path d="M12 4L4 12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4 4L12 12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "CloseIcon",
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>

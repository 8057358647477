<template>
  <li class="drag-shelf-header" :class="[zoneType === 'shelving-7-Lays' ? 'lays' : 'team']"
      v-if="zoneType === 'shelving-7-Lays' || zoneType === 'shelving-7-Хрустteam' || zoneType === 'shelving-7-empty'"
  >
    <img :src="require(`@/assets/img/logo-lays.png`)" alt="Lays logo" v-if="zoneType === 'shelving-7-Lays'">
    <img :src="require(`@/assets/img/logo-hrustteam.png`)" alt="Lays Хрустteam" v-else-if="zoneType === 'shelving-7-Хрустteam'">
  </li>
</template>

<script>

export default {
  name: 'ShelfHeader',
  props: {
    zoneType: {
      type: String,
      default: ''
    }
  }
}

</script>

<style scoped lang="scss">
@import "../assets/scss/common";

.drag-shelf-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $team-color;

  img {
    width: 100rem;
    height: 56rem;
  }

  &.lays {
    background-color: $lays-color;

    img {
      width: 75rem;
      height: 56rem;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -15rem;
    display: block;
    width: 100%;
    height: 13rem;
    background-color: $shelf-color;
    border-width: 1rem;
    border-style: solid;
    border-color: $shelf-border;
    box-shadow: 0 6rem 8rem rgba(221, 220, 220, .5);
  }
}

</style>
<template>
  <section class="wrapper">
    <Controls
      refs="controls"
      @refreshData="refreshData"
      :clickedRack="clickedRack"
    />
    <ul
      class="drag-list"
      v-drag-and-drop:options="options"
      :style="{
        transform: `scale(${stellageZoomStyle.scale}) translate(-${stellageZoomStyle.x}px, -${stellageZoomStyle.y}px)`
      }"
      ref="dragListWrapper"
      :class="{
          active: isZooming,
          wide: (con === '6' || con === '5')
      }"
    >
      <li
        class="drag-column"
        v-for="(zone, i) in zones"
        :key="zone.uuid"
        :class="[
            { ['stellage' + zone.id]: zone.type === 'shelving'},
            `${zone.type}`,
            nextZoneType(zones, i)
          ]"
      >
        <ul
          class="drag-inner-list"
          :data-id="zone.uuid"
          v-if="zone.racks"
        >

          <ShelfHeader :zone-type="zone.type"/>

          <li
            class="drag-shelf"
            :class="[{['p' + zone.id + shelf.pos]: zone.type !== 'basket'}, `_bg-color-${shelf.background}`]"
            v-for="shelf in zone.racks"
            :key="shelf.uuid"
            :data-id="shelf.uuid"
          >
            <vue-draggable-group
              v-model="shelf.pens"
              :groups="zone.racks"
              :data-id="shelf.uuid"
              items-key="pens"
            >
              <ul
                v-if="shelf.pens"
                class="drag-shelf-content"
                :data-id="shelf.uuid"
                :data-type="zone.type"
              >
                <li
                    class="drag-shelf__background"
                    :data-type="zone.type"
                    @click="onClickRack($event)"
                    ref="rackBg"
                />

                <Item
                  v-for="item in shelf.pens"
                  :key="item.uuid"
                  :item="item"
                  :shelfId="shelf.uuid"
                  :zonetype="zone.type"
                  :isTouchDevice="isTouchDevice"
                />
              </ul>
            </vue-draggable-group>
          </li>
        </ul>
      </li>
    </ul>

    <div class="drag-list basket"
         @mouseenter="basketHovered()"
         @mouseleave="basketUnHovered()"
         @click="showContentBasket"
         v-drag-and-drop:options="options"
    >
      <div
        class="drag-inner-list"
        :class="[basketZone.racks[0].pens.length > 0 && isShowContentBasket ? 'active' : '']"
        :data-id="basketZone.uuid"
        v-if="basketZone.racks"
      >
        <span class="basket-total">{{ basketZone.racks[0].pens.length }}</span>
        <IconCart :lang="lang" :active="basketZone.racks[0].pens.length > 0 && isShowContentBasket"/>
        <div
          class="drag-shelf"
          v-for="shelf in basketZone.racks"
          :key="shelf.uuid"
          :data-id="shelf.uuid"
        >
          <vue-draggable-group
            v-model="shelf.pens"
            :groups="basketZone.racks"
            :data-id="shelf.uuid"
            items-key="pens"
          >
            <ul
              v-if="shelf.pens"
              class="drag-shelf-content"
              id="basket"
              :data-id="shelf.uuid"
              :data-type="basketZone.type"
              :class="[basketZone.racks[0].pens.length > 0 && isShowContentBasket ? 'active' : '',
                       basketZone.racks[0].pens.length > 3 ? 'scrolled' : '' ]"
            >
              <Item
                v-for="(item, index) in shelf.pens"
                :key="item.uuid + index"
                :item="item"
                :basketPos="basketPos"
                :shelfId="shelf.uuid"
                :zonetype="basketZone.type"
                :isTouchDevice="isTouchDevice"
              />
            </ul>
          </vue-draggable-group>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Item from "./Item";
import Controls from "@/components/Controls.vue";
import ShelfHeader from "@/components/Shelf-header.vue";
import IconCart from "@/components/icons/IconCart.vue";

export default {
  name: "DragList",
  props: {
    options: { type: Object },
    initialZones: { type: Array },
    zones: { type: Array },
    basketZone: { type: Object },
    lang: { type: String, default: '' },
    con: { type: String, default: '' }
  },
  data() {
    return {
      basketPos: {},
      stellageZoomStyle: {
          scale: 1,
          x: 0,
          y: 0,
      },
      isZooming: false,
      isShowContentBasket: false,
      clickedRack: null,
    };
  },
  components: {IconCart, Controls, Item, ShelfHeader},
  computed: {
    isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
  },
  methods: {

    refreshData(data) {
      this.stellageZoomStyle = data.stellageZoomStyle;
      this.isZooming = data.isZooming;
    },

    basketHovered() {
      this.$emit('basketHovered')
    },

    basketUnHovered() {
      this.$emit('basketUnHovered')
    },

    showContentBasket() {
      this.isShowContentBasket = !this.isShowContentBasket
      this.$emit('showContentBasket', this.isShowContentBasket);
    },

    onClickRack(e) {
      this.clickedRack = e;
    },

    nextZoneType(zones, index) {
      if (!zones[index + 1]) return;
      return (zones[index + 1].type === 'lenta' && zones[index + 2]) ? 'next-is-lenta' : '';
    }
  },

  watch: {
    isZooming(value) {
      this.$emit('isZoomed', value)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/common";

.wrapper {
  position: relative;
  overflow: hidden;
  min-width: 1440rem;
  width: max-content;
  height: 100vh;
  padding: 68rem 42rem 97rem 42rem;
  background: url('../assets/img/bg.png') no-repeat;
  background-size: auto 100%;
  @media (max-width: 769px) {
      overflow: scroll;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    background: url('../assets/img/bg-1.png');
    background-size: auto 100%;
    z-index: -1;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 41rem;
    background: url('../assets/img/right-wall.png') no-repeat;
    background-size: auto 100%;
    pointer-events: none;
  }
}

.drag-list {
  display: grid;
  grid-template-columns: min-content;
  grid-auto-flow: column;
  gap: 25rem;
  width: fit-content;
  max-height: 630rem;
  background-color: #FFFEFC;
  transform-origin: top left;
  transition: all 1s ease-out, transform-origin 0s;
  will-change: transform;

  &.basket {
    position: fixed;
    bottom: 5rem;
    display: block;
    cursor: pointer;
    width: 146rem;
    height: 83rem;
    z-index: 40;
    transition: all 0.25s ease-in-out;
  }

  &.wide {
    gap: 80rem;

    .drag-column {

      &:not(:nth-child(2), .lenta) {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -25rem;
          width: 27rem;
          height: 693rem;
          background: url("../assets/img/rack.png") no-repeat 0% / cover;
        }
      }

      &.lenta {
        margin-right: -56rem;
      }
    }
  }
}

.drag-column {
  position: relative;
  width: 100%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:first-child:not(.lenta)::before, &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -25rem;
    width: 27rem;
    height: 693rem;
    background: url("../assets/img/rack.png") no-repeat 0% / cover;
  }

  &:first-child {
    &::before {
      left: -25rem;
      right: auto;
    }

    &:not(.lenta) {
      width: calc(100% - 31rem);
      margin-left: 31rem;
      &::before {
        left: -27rem;
      }
    }
  }

  &:last-child {
    &:not(.lenta) {
      width: calc(100% - 31rem);
      margin-right: 31rem;
      &::after {
        right: -27rem;
      }
    }
  }

  &.next-is-lenta {
    &::after {
      content: none;
    }
    & ~ .lenta {
      margin: 0 -25rem;
    }
  }

  &.lenta {
    width: 115rem;

    &::before {
      content: none !important;
    }

    &:not(:first-child, :last-child) {
      &::after {
        content: none;
      }
    }

    &:last-child::after {
      content: none !important;
    }
  }
}

.drag-inner-list {
  display: grid;
  grid-template-rows: 153rem repeat(5, 82rem);
  gap: 15rem;
  align-items: end;
  height: auto;
  padding-bottom: 30rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .lenta & {
    display: block;
    height: 100%;
    padding-bottom: 0;
  }

  .shelving-7-Lays &, .shelving-7-Хрустteam &, .shelving-7-empty & {
    grid-template-rows: 56rem repeat(6, 82rem);
  }

  .basket & {
    grid-template-rows: 1fr;
    max-width: unset;
    overflow: visible;
    pointer-events: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &.active {

      .basket-total {
        color: $primary;
        background-color: white;
      }
    }

      .item-dropzone-area {
        position: absolute;
        display: block;
        width: 100%;
        height: 50%;
        align-self: flex-end;
        background: green;
        z-index: -1;
      }
  }
}

.drag-shelf {
  position: relative;
  height: 100%;
  cursor: pointer;
  transition: 0.2s;

  &::after {
    content: "";
    position: absolute;
    bottom: -15rem;
    display: block;
    width: 100%;
    height: 13rem;
    background-color: $shelf-color;
    border-width: 1rem 0;
    border-style: solid;
    border-color: $shelf-border;
    box-shadow: 0 6rem 8rem rgba(221, 220, 220, .5);
  }

  &__background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: zoom-in;
    transition: 0.2s;
    padding-top: 45rem;
    opacity: 0.4;
    &:hover {
        background: rgba(80, 48, 171, 0.2);
    }
    @media (max-width: 768px) {
        display: none;
    }
  }

  &._bg-color {
    &-seryi {
      background-color: $shelf-grey;
    }

    &-zeltyi {
      background-color: $shelf-yellow;
    }
  }

  .lenta & {
    &::after {
      content: none;
    }
  }

  .basket & {
    &::after {
      content: none;
    }
  }
}

.drag-shelf-content {
  position: relative;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  height: 100%;
  padding: 0 4.5rem;
  z-index: 2;
  transition: 0.2s;

  .lenta & {
    display: grid;
    align-items: start;
    justify-content: center;
    gap: 5rem;
    height: auto;
    padding: 0 0 10rem 0;

    &::before {
      content: '';
      display: block;
      width: 75rem;
      height: 56rem;
      margin: 10rem 0 11rem;
      background: url('../assets/img/logo-lays.png') no-repeat 100% / contain;
    }

    .drag-shelf__background {
      padding-top: 0;
    }
  }

  .basket & {
    position: relative;
    top: 0;
    transform: translateY(calc(83rem - 100%));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 83rem 0;
    height: 83rem;
    border-radius: 4px;
    overflow-y: hidden;
    overflow-x: hidden;
    pointer-events: all;

    &.scrolled {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: block;
        width: 26px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-track {
        background: white;
      }

      &::-webkit-scrollbar-thumb {
        width: 6px;
        border: 10px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #AAAAAA;
      }

      &::-webkit-scrollbar-track-piece:end {
        background: transparent;
        margin-bottom: 10rem;
      }

      &::-webkit-scrollbar-track-piece:start {
        margin-top: 10rem;
        border-radius: 50px;
      }
    }

    &.active {
      padding: 0;
      top: -171rem;
      height: 335rem;
    }
  }
}

@keyframes nodeInserted {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;
  }
}

.item-dropzone-area {
  height: 6rem;
  background: #888;
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: nodeInserted;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

.basket-total {
  position: absolute;
  top: 4rem;
  right: 25rem;
  padding: 3px 11px 2px 11px;
  background-color: $primary;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 5;
  font-size: 11rem;
  line-height: 1.1;
  font-weight: 700;
}

</style>

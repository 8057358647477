<template>
  <div class="modal"
       :class="{'_popup': !posModal, '_transition': displayStartInfo && screenNumber === 1}"
       @click="clickOutside($event)"
  >
    <div class="modal__content"
         :class="{'_hover': posModal}"
         :style="style"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "Modal",
  props: {
    posModal: { type: Object, required: false },
    displayStartInfo: { type: Boolean, required: true },
    displayDetails: { type: Boolean, required: false },
    screenNumber: { type: Number, required: true }
  },
  data() {
    return {
      canClose: true,
    }
  },
  computed: {
    style() {
      return this.posModal
        ? {
          top:  this.posModal.top,
          left: this.posModal.left,
          pointerEvents: 'none',
        }
        : ''
    },
  },
  methods: {
    clickOutside(e) {
      if (e.target.classList.contains('modal') && this.canClose) {
        eventBus.$emit('close modal', {setTimout: this.displayStartInfo});
      }
    }
  },
  created () {
    eventBus.$on('can close outside', (data) => {
      this.canClose = data;
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/common";

@keyframes sheer {
  0% {
    background-color: rgba($black, .8);
    opacity: .2;
  }
  50% {
    background-color: rgba($black, .5);
    opacity: 1;
  }
  100% {
    background-color: transparent;
    opacity: 1;
  }
}

.modal {

  &._popup {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    background-color: $modal-bg;
  }

  &._transition {
    animation: sheer 2s;
    background-color: $modal-bg;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    gap: 16rem;
    width: 345rem;
    padding: 12rem;
    background-color: $white;
    border-radius: 4rem;
    transform: translate(-50%, -50%);
    z-index: 40;

    ._popup & {
      width: auto;
      max-width: 100%;

      @media (max-width: 768px) {
        width: calc(100% - 32rem);
      }
    }

    &._hover {
      padding: 8rem 6rem;
    }
  }
}

</style>
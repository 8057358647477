<template>
  <div class="horizontal-scroll"
       @wheel.prevent ="wheel"
       @scroll.prevent ="wheel"
       ref="horizontal">
    <div class="js-rem-example" ref="remEx"></div>
    <DragList
        v-if="zones && zones.length > 0"
        :options="options"
        :initialZones="initialZones"
        :zones="zones"
        :basketZone="basketZone"
        :lang="lang"
        :con="con"
        @isZoomed="onZoom"
        @showContentBasket="toggleShowContentBasket"
        @basketHovered="isBasketHovered = true"
        @basketUnHovered="isBasketHovered = false"
    />

    <Arrow class="_left"
           v-if="(horPosition > scrollGap) && !isZooming"
           @click.native="scrollHorizontal('left')" />
    <Arrow class="_right"
           v-if="(horPosition < maxScrollLeft - scrollGap) && !isZooming"
           @click.native="scrollHorizontal('right')" />

    <div class="button basket-price" v-if="showContentBasket && basketZone.racks[0].pens.length > 0">
      Стоимость покупки: <span>{{ basketPrice }}</span>
    </div>

    <div class="hints" v-if="!(showModal && displayStartInfo) && !isZooming">
      <div class="hints__item" v-html="text.con5.step0.ru" v-if="con === '5' && screenNumber === 1"/>
      <div class="hints__item" v-html="text.con5.step3.ru" v-else-if="con === '5' && screenNumber === 4"/>
      <div class="hints__item" v-html="text.findProduct[`step${screenNumber - 1}`].ru" v-else/>
      <div class="hints__item" v-html="text.con5.step0[lang]" v-if="con === '5' && screenNumber === 1"/>
      <div class="hints__item" v-html="text.con5.step3[lang]" v-else-if="con === '5' && screenNumber === 4"/>
      <div class="hints__item" v-html="text.findProduct[`step${screenNumber - 1}`][lang]" v-else/>
    </div>

    <a class="link-button button" @click="nextScreen" v-if="basketItemsQnty && !isZooming">
      <IconAway/>
      <div>
        <span>{{text.complete.ru}}</span>
        <span>{{text.complete[lang]}}</span>
      </div>

    </a>

    <Modal :item="item"
           :posModal="posModal"
           :displayStartInfo="displayStartInfo"
           :displayDetails="displayDetails"
           :screenNumber="screenNumber"
           v-if="showModal && (displayDetails || displayStartInfo)">
      <ItemDetails :item="item"
                   :posModal="posModal"
                   :shelfId="shelfId"
                   :basketItemsQnty="basketItemsQnty"
                   :lang="lang"
                   v-if="displayDetails"/>
      <StartInfo :screenNumber="screenNumber"
                 :screenImage="screenImage"
                 :lang="lang"
                 :con="con"
                 v-else
      />
    </Modal>
    <MobileDrag :src="mobileSrc" :pos="mobilePos" v-if="mobileSrc"/>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import Modal from "@/components/Modal";
import DragList from "@/components/DragList";
import ItemDetails from "@/components/modal-content/ItemDetails";
import StartInfo from "@/components/modal-content/StartInfo";
import MobileDrag from "@/components/MobileDrag";
import Arrow from "@/components/Arrow";
import IconAway from "@/components/icons/IconAway.vue";
import lang_texts from "@/assets/js/lang_texts";

export default {
  name: 'Shelves',
  data() {
    return {
      uid: '',
      con: '',
      lang: '',
      ticket: '',
      showContentBasket: false,

      horPosition: 0,
      maxScrollLeft: null,
      scrollGap: null,
      isTouch: false,
      scrollInterval: null,
      isBasketHovered: false,
      screens: [],
      initialZones: [],
      screenNumber: 1,
      screenTimestamp: null,
      zones: [],
      basketZone: {
        id: '0',
        name: "basket",
        type: 'basket',
        racks: [
          {
            id: '0',
            uuid: '0',
            pens: []
          }
        ]
      },
      screenImage: null,
      basketProductsIds: '',
      shownProductsIds: [],
      item: {},
      showModal: true,
      displayStartInfo: true,
      posModal: null,
      shelfId: '',
      mobileSrc: '',
      mobilePos: {
        top: 0,
        left: 0
      },

      isZooming: false,

      options: {
        dropzoneSelector: ".drag-shelf-content",
        draggableSelector: window.innerWidth > 768 ? ".drag-item" : "",
        onDragstart: (e) => {
          let oldGroupId = e.owner.dataset.id
          let elementId = e.items[0].dataset.id
          if (oldGroupId == 0 && e.droptarget === null) {
            setTimeout(() => {
              this.removeElementFromBasket(elementId)
            }, 500)
          }
        },
        onDragend: async (e) => {
            let newGroupId = e.droptarget.dataset.id
            let oldGroupId = e.owner.dataset.id
            let elementId = e.items[0].dataset.id
            if (newGroupId == oldGroupId) {
                e.stop()
            } else if (newGroupId == 0 && oldGroupId != 0) {
              this.putElementToBasket(oldGroupId, elementId)
              if (this.$gtm.enabled()) {
                this.$gtm.trackEvent({
                  event: 'Basket-Add',
                  category: 'Basket',
                  action: 'Add',
                  label: elementId,
                  value: new Date(),
                })
              }

              // track
              try {
                await this.track({
                  con: this.con,
                  uid: this.uid,
                  ticket: this.ticket,
                  e: e.items[0],
                  event: "add_to_basket",
                  screen: this.screenNumber,
                })
              } catch (e) {
                console.error(e)
              }
            } else if (oldGroupId == 0) {
              this.removeElementFromBasket(elementId)
            } else {
              e.stop()
            }
            e.stop()
        }
      },
      scrolledBasketElementPosition: 0,

      text: lang_texts,
    };
  },
  components: {
    IconAway,
    Modal,
    DragList,
    ItemDetails,
    StartInfo,
    Arrow,
    MobileDrag
  },
  methods: {
    wheel(e) {
      if (this.isBasketHovered) {
        let basket = document.getElementById('basket')
        let basketElements = basket.getElementsByTagName('li')
        if (e.deltaY > 0) {
          if (this.scrolledBasketElementPosition < basketElements.length) {
            this.scrolledBasketElementPosition++
          }
        } else {
          if (this.scrolledBasketElementPosition > 0) {
            this.scrolledBasketElementPosition--
          }
        }

        if (basketElements && basketElements[this.scrolledBasketElementPosition]) {
          basketElements[this.scrolledBasketElementPosition].scrollIntoView();
        }

      } else if (this.isZooming) {
        return
      } else {
        if (e.deltaY) {
          this.$refs.horizontal.scrollLeft += e.deltaY;
        } else if (e.deltaX) {
          this.$refs.horizontal.scrollLeft += e.deltaX;
        }
        this.horPosition = this.$refs.horizontal.scrollLeft;
      }
    },

    scrollHorizontal(dir) {
      const scrollLeft = this.isTouch ? this.$refs.horizontal.scrollLeft - this.inRem(246) : this.$refs.horizontal.scrollLeft - this.inRem(1000);
      const scrollRight = this.isTouch ? this.$refs.horizontal.scrollLeft + this.inRem(246) : this.$refs.horizontal.scrollLeft + this.inRem(1000);
      if (dir === 'left') {
        this.$refs.horizontal.scrollTo({left: scrollLeft, behavior: 'smooth'})
        this.horPosition = scrollLeft;
      } else {
        this.$refs.horizontal.scrollTo({left: scrollRight, behavior: 'smooth'})
        this.horPosition = scrollRight;
      }
    },

    onZoom(val) {
      this.isZooming = val;
    },

    toggleShowContentBasket(showContentBasket) {
      this.showContentBasket = showContentBasket
    },

    async getBackendData(con) {
      await this.fetchData(con);

      setTimeout(() => {
        this.maxScrollLeft = this.$refs.horizontal.scrollWidth - this.$refs.horizontal.clientWidth;
        this.scrollGap = this.maxScrollLeft / 10;
      }, 500)
    },

    async fetchData(con) {
      try {
        await this.$http.get(`${process.env.VUE_APP_API_URL}/api/scripts/${con}`)
          .then(response => {
            return response.data;
          })
          .then(data => {
            if (data.zones ) {
              this.screens = data.screen_ids;
              this.screenImage = data.screen_img;

              // detect current language
              this.lang = data.lang_name;

              this.initialZones = data.zones;
              this.zones = this.cloneOverJson(this.initialZones);
              this.basketZone.racks = [{
                id: '0',
                uuid: '0',
                pens: []
              }]
            }
          });
      } catch (error) {
        console.log(error);
      }
    },

    putElementToBasket(oldGroupId, elementId) {
      if (this.basketZone.racks[0].pens.length < 10) {
        const {zoneIndex, groupIndex, elementIndex} = this.findElement(elementId, this.zones, oldGroupId, 'put');
        const clonedElement = this.cloneOverJson(this.zones[zoneIndex].racks[groupIndex].pens[elementIndex]);
        // this.zones[zoneIndex].racks[groupIndex].pens[elementIndex].image = '';
        this.basketZone.racks[0].pens.push(clonedElement);
      }
    },
    removeElementFromBasket(elementId) {
      /*const {zoneIndex, groupIndex, elementIndex} = this.findElement(elementId, this.initialZones, elementId, 'remove')

      const removedElement = this.cloneOverJson(this.basketZone.racks[0].pens
          .splice(this.basketZone.racks[0].pens.findIndex(el => el.uuid == elementId), 1)[0])

      this.zones[zoneIndex].racks[groupIndex].pens[elementIndex].image = removedElement.image*/

      this.cloneOverJson(this.basketZone.racks[0].pens
          .splice(this.basketZone.racks[0].pens.findIndex(el => el.uuid == elementId), 1)[0]);
    },
    findElement(elementId, zones, id, action) {
      const zoneIndex = action === 'put'
          ? zones.findIndex(zone => zone.racks.some(group => group.uuid == id))
          : zones.findIndex(zone => zone.racks.some(group => group.pens.some(el => el.uuid == elementId)));
      const groupIndex = action === 'put' && zones[zoneIndex] && zones[zoneIndex].racks
          ? zones[zoneIndex].racks.findIndex(group => group.uuid == id)
          : zones[zoneIndex].racks.findIndex(group => group.pens.some(el => el.uuid == elementId));
      const elementIndex = zones[zoneIndex].racks[groupIndex].pens.findIndex(el => el.uuid == elementId);
      return {zoneIndex, groupIndex, elementIndex};
    },

    async nextScreen() {
      // track shown (mouse over) products
      try {
        await this.track({
            con: this.con,
            uid: this.uid,
            ticket: this.ticket,
            screen: this.screenNumber,
            event: 'next_screen',
            shown: this.shownProductsIds,
            duration: (this.screenTimestamp - new Date()) * -1 / 1000
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.shownProductsIds = []
      }

      const arr = this.basketZone.racks[0].pens.map(pen => pen.sku);
      this.basketProductsIds += this.basketProductsIds ? '%2C' + arr.join('%2C') : arr.join('%2C');

      this.screenNumber++;

      if (this.screenNumber > 4) {
        // const skipto = this.screenNumber === 2 ? '&sys_skipto=Q1x2' : '&sys_skipto=Q1'
        window.location.href = 'https://www.dooblocawi.com/client/Survey.aspx?Ticket='
            + this.ticket +
            '&uid=' + this.uid
            // + skipto;
      } else {
        //clear basket to start again
        this.basketZone.racks = [{
          id: '0',
          uuid: '0',
          pens: []
        }]

        this.posModal = null;
        this.showModal = true;
        this.displayStartInfo = true;
        this.screenTimestamp = new Date();
      }

    },

    modalPosition(data) {
      let pos = data.pos

      if (data.pos && this.$refs.horizontal) {
        const modalWidth = {
          top: this.$refs.horizontal.offsetHeight - this.inRem(90),
          left: this.inRem(345 / 2)
        };
        let top = `${modalWidth.top}px`;
        let left = `${data.pos.left}px`;

        if (modalWidth.top - data.pos.top > this.inRem(90)) {
          top = `${data.pos.top + this.inRem(90)}px`;
        }

        if (this.$refs.horizontal.offsetWidth - this.inRem(345 / 2) < data.pos.left) {
          left = `${this.$refs.horizontal.offsetWidth - this.inRem(345 / 2)}px`
        } else if (modalWidth.left > data.pos.left) {
          left = `${modalWidth.left}px`;
        }

        pos = {
          top: top,
          left: left
        };

        setTimeout(() => {
          if (this.posModal) {
            this.showModal = true;
          }
        }, 200)
      } else {
        this.showModal = true;
      }

      this.item = data.item;
      this.posModal = pos;
      if (data.shelfId) {
        this.shelfId = data.shelfId;
      }
    },

    windowWidthCount() {
      this.isTouch = (window.innerWidth < 769) ||
      (('ontouchstart' in window) ||
          (navigator.maxTouchPoints > 0) ||
          (navigator.msMaxTouchPoints > 0))
          ? true : false;
    },

  },
  computed: {
    // defines if it's an item details popup or message popup
    displayDetails() {
      return this.item && Object.keys(this.item).length > 0
    },
    // products quantity in basket
    basketItemsQnty() {
      return this.basketZone.racks[0].pens ? this.basketZone.racks[0].pens.length : 0
    },
    basketPrice() {
      let currency = '';
      return this.basketZone.racks[0].pens ?
        this.basketZone.racks[0].pens.reduce((acc, item) => {
          // find price currency
          currency = item.price.replace(/([0-9.])*/g, '').trim();
          // find price in numbers and math total price in basket
          const price = item.price.replace(/\s/g, '').replace(/,/g, '.').trim().match(/([0-9.])*/g)[0];
          return Math.round((acc + +price) * 10) / 10;
        }, 0) + ' ' + currency // price + currency
        : 0
    }
  },
  mounted() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    this.con = url.pathname.match(/\d+/)[0];
    this.uid = url.searchParams.get("uid");
    this.ticket = url.searchParams.get("Ticket");

    this.getBackendData(this.con).then(() => {
      this.screenTimestamp = new Date()
    });

    window.addEventListener('load', this.windowWidthCount);

    window.addEventListener('resize', this.windowWidthCount);
  },
  created() {
    eventBus.$on('open modal', data => {
      if (data.pos != null) {
        this.shownProductsIds.push(data.item.id)
      }

      this.modalPosition(data)
    })
    eventBus.$on('close modal', data => {

      // as starting info modal is closed set timer
      if (data && data.setTimout && this.screenNumber > 1 ) {
        const TIME = 3; // 3 min
        setTimeout(() => {
          // show next screen
          this.nextScreen();
        }, TIME * 1000 * 60)
      }

      this.item = {};
      this.showModal = false;
      this.displayStartInfo = false;
    })
    eventBus.$on('remove item', elementId => {
      this.removeElementFromBasket(elementId);
      if (this.item.uuid === elementId) {
        this.item = {};
        this.showModal = false;
      }
    })
    eventBus.$on('mobile drag', (data) => {
      this.mobileSrc = data.src;
      this.mobilePos.top = data.pos.top;
      this.mobilePos.left = data.pos.left;
    })
    eventBus.$on('move to basket', async (data) => {
      this.putElementToBasket(data.shelf, data.uuid)

      // track
      try {
        await this.track({
          con: this.con,
          uid: this.uid,
          ticket: this.ticket,
          e: document.querySelectorAll(`li[data-id="${data.uuid}"]`)[0],
          event: "add_to_basket",
          screen: this.screenNumber,
        })
      } catch (e) {
          console.error(e)
      }
    })
    eventBus.$on('on zoom', (val) => {
      if (val) {
        this.$refs.horizontal.scrollTo({left: 1, behavior: 'smooth'})
      } else {
        this.$refs.horizontal.scrollTo({left: this.horPosition, behavior: 'smooth'})
      }
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.windowWidthCount)
  }
};
</script>

<style lang="scss">
@import "../assets/scss/common";

.horizontal-scroll {
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.link-button {
  position: fixed;
  right: 53rem;
  bottom: 20rem;
  padding: 14rem 27rem;
  border-bottom-right-radius: 8rem;
  cursor: pointer;
  z-index: 10;

  svg {
    width: 28rem;
    height: 28rem;
    fill: $primary;
  }

  &:hover svg {
    fill: $white;
  }

  div span {
    display: block;
  }
}

.basket-price.button {
  position: fixed;
  left: 38rem;
  bottom: 92rem;
  pointer-events: none;
  width: 146rem;
  height: 82rem;
  text-align: left;
  font-size: 10rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.2;
  z-index: 40;

  span {
    font-size: 20rem;
    font-weight: 700;
    line-height: 1;
  }
}

.hints {
  position: fixed;
  left: 197rem;
  bottom: 5rem;
  display: flex;
  height: 83rem;
  font-size: 13rem;
  line-height: 1.08;
  z-index: 2;
  pointer-events: none;

  &__item {
    display: flex;
    align-items: center;
    width: 421rem;
    padding: 6rem;
    background-color: $white;
    border-radius: 0 4rem 4rem 0;
    border: 1rem solid $primary;

    &:first-of-type {
      border-radius: 4rem 0 0 4rem;
      border-right: none;
    }
  }

  @media (max-width: 768px) {
    top: 0;
    bottom: auto;
    left: 50%;
    flex-direction: column;
    height: auto;
    font-size: 12rem;
    background-color: rgba($white, .3);
    transform: translateX(-50%);

    &__item {
      padding: 8rem;

      &:first-of-type {
        border-right: none;
        border-bottom: 1rem solid $primary;
      }
    }
  }
}

</style>
